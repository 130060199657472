import BandwagonDotCom from "../components/BandwagonDotCom"
import HeroBar from '../components/HeroBar'
import MobileMenu from "../components/MobileMenu"
import React from "react"

import { Helmet } from 'react-helmet'
import { Link } from "gatsby"

import './contact.scss';

const Contact = () => <BandwagonDotCom>
    <Helmet>
        <script type="text/javascript">{`
           function onSubmit(token) {
             document.getElementById('contactus').submit();
           }
        `}</script>
    </Helmet>
  <MobileMenu>
    <Link to="/contact">Contact</Link>
    <Link className="hiring" to="https://bandwagon.breezy.hr/">We're&nbsp;Hiring!</Link>
    <Link to="/">Home</Link>
    <Link to="/team">Team</Link>
  </MobileMenu>
  <HeroBar />
  <div class="contact-content">
      <h1>Get In Touch</h1>
      <p>
        We want to hear from you! We are building identity infrastructure
        solutions to help you connect with your fans, analyze your audience,
        create better experiences. To learn more about how we can help, tell us
        a little about you and we will follow up.
      </p>
      <form id="contactus"
            action="https://formkeep.com/f/6a41213f28e1"
            accept-charset="UTF-8"
            enctype="multipart/form-data"
            method="POST">
          <div class="input-row">
            <div class="input">
                <label htmlFor="fullName">Full Name</label>
                <input class="simple-input" id="fullName" name="fullName" />
            </div>
            <div class="input">
                <label htmlFor="email">Email</label>
                <input class="simple-input" type="email" id="email" name="email" />
            </div>
          </div>
          <div class="input-row">
            <div class="input">
                <label htmlFor="organization">Organization</label>
                <input class="simple-input" id="organization" name="organization" />
            </div>
            <div class="input">
                <label htmlFor="product">
                    What product are you interested in?
                </label>
                <select class="simple-input" name="product" id="product">
                    <option value="idealseat">IdealSeat</option>
                    <option value="poet">POET</option>
                </select>
            </div>
          </div>
          <div class="input-row">
              <div class="input">
                  <label htmlFor="message">Message</label>
                  <textarea id="message" name="message" />
              </div>
          </div>
          <div class="input-row" style={{display: "none"}}>
            <input name="subscribe_6a41213f28e1_37699" />
          </div>
          <div class="submit-row">
              <button
                      class="g-recaptcha"
                      data-sitekey="6Lf0RnseAAAAACco6qvDn5wP3_WPAl0hF-1LWMIw"
                      data-callback="onSubmit"
                      data-action="contactus"
                      id="contact-us-submit">
                  Send
              </button>
          </div>
      </form>
  </div>
</BandwagonDotCom>;

export default Contact
